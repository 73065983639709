//
// This is used both for the root scss files (main.scss, critical.scss etc),
// but also for the scss components, which are not inside of the roots.
//

.wizard {
  .header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding-bottom: 5px;
    img {
      width: 100px;
      padding: 5px;
    }
    .logout-section {
      display: flex;
      align-items: center;
      p {
        font-size: 13px;
        padding: 0 15px;
        margin: 0;
      }
    }
  }

  .MuiFormControl-root {
    // height: 45px;
    width: 100%;
  }
}
