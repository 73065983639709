//
// This is used both for the root scss files (main.scss, critical.scss etc),
// but also for the scss components, which are not inside of the roots.
//

.dashboard {
  .widget {
    background-color: #fff;
  }
  .mileage-pollution-chart {
    height: 50vmin;
  }

  @media (max-width: 767.98px) {
    .mileage-pollution-chart {
      min-height: 350px;
    }
  }

  .fullscreen {
    .mileage-pollution-chart {
      height: calc(100vh - 48px);
    }
  }
}
