//
// This is used both for the root scss files (main.scss, critical.scss etc),
// but also for the scss components, which are not inside of the roots.
//

/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.9.3
 *
*/

// Variables, Mixins
@import "base/variables";
@import "base/mixins";

// INSPINIA Theme Elements
@import "base/typography";
@import "base/glyphicons";
@import "base/navigation";
@import "base/top_navigation";
@import "base/buttons";
@import "base/badges_labels";
@import "base/elements";
@import "base/sidebar";
@import "base/base";
@import "base/pages";
@import "base/chat";
@import "base/metismenu";
@import "base/spinners";

// Landing page
@import "base/landing";

// RTL Support
@import "base/rtl";

// INSPINIA Skins
@import "base/skins";
@import "base/md-skin";

// Media query style
@import "base/media";

// Custom style
// Your custom style to override base style
@import "base/custom";

@import "vendor/sidebar";

// Clear layout on print mode
@media print {
  nav.navbar-static-side {
    display: none;
  }

  body {
    overflow: visible !important;
  }

  #page-wrapper {
    margin: 0;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media print {
  @page {
    margin: 25mm 0 0 0;
    size: A4 landscape;
  }
  .MuiDataGrid-colCellTitle,
  .MuiDataGrid-cell {
    font-size: 22px;
  }
  .MuiDataGrid-toolbar,
  .MuiDataGrid-footer {
    display: none !important;
  }
}

.button-danger {
  background-color: #ff0000;
  color: #fff;
  &:hover,
  :focus {
    background-color: #a70000;
  }
}

// .MuiDataGrid-root .MuiDataGrid-colCellTitle{
//   font-weight: 600;
// }
#country-menu .MuiMenu-paper,
ul[class^="materialui-daterange-picker-MuiList-root"] {
  height: 30vh;
}

.MuiDataGrid-main .MuiDataGrid-window,
.MuiAutocomplete-listbox,
#country-menu .MuiMenu-paper.MuiPopover-paper,
.MuiPickersYearSelection-container,
div[class^="MUITableBody-root"],
ul[class^="materialui-daterange-picker-MuiList-root"],
ul[class^="MuiAutocomplete-listbox"] {
  overflow-y: hidden !important;

  &:hover {
    overflow-y: auto !important;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #fff;
    background-image: -webkit-gradient(
      linear,
      40% 0%,
      75% 84%,
      from(#676a6c),
      to(#676a6c),
      color-stop(0.6, #676a6c)
    );
  }
}

.costCenterName {
  .MuiAutocomplete-popper {
    width: 370px !important;
  }
}

.profile-work-phone {
  width: 100%;
  .MuiInputBase-adornedStart {
    top: 0;
  }
}

.MuiInputBase-adornedStart {
  top: -3px;
  margin-bottom: -3px;
}

.MuiChip-deleteIcon {
  :hover {
    color: #1ab394;
  }
}

.MuiFormHelperText-root.Mui-error {
  color: #f44336;
}

.MuiFormHelperText-marginDense {
  margin-top: 4px;
}

.personal-info-tabs {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    background: #fff;
    height: 10px;
    bottom: -8px;
    left: 0;
  }
}

// div[title="Sort"] {
//   pointer-events: none;
// }

// p[title] {
//   pointer-events: visible;
// }
// .Mui-selected {
//   position:relative !important;
// &::after {
//   content: "";
//   position: absolute;
//   width: 115px;
//   background: #fff;
//   height: 10px;
//   bottom: -8px;
//   left: 0;
// }
// }

// .users-columns {
//   .MuiDataGrid-colCell, .MuiDataGrid-cell{
//     max-width: 180px!important;
//     min-width: 180px!important;
//     width: 180px!important;
//   }
// }

.data-table-wrapper {
  // .MuiToolbar-root {
  //   div[class^="MUIDataTableToolbar-actions"] {
  //     flex: none;
  //   }
  //   div[class^="MUIDataTableToolbar-left"] {
  //     flex: auto;
  //     .MuiFormControl-root {
  //       width: 100%;
  //     }
  //   }
  // }

  .MuiToolbar-gutters {
    padding-right: 0;
    padding-left: 0;
  }

  // div[class^="MUITableBody-root"],
  div[class^="MUIDataTable-responsiveBase"] {
    &:hover {
      &::-webkit-scrollbar-track,
      &::-webkit-scrollbar,
      &::-webkit-scrollbar-thumb {
        visibility: visible;
      }
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      background-color: #f5f5f5;
      border-radius: 10px;
      visibility: hidden;
    }

    &::-webkit-scrollbar {
      height: 6px;
      width: 6px;
      background-color: #f5f5f5;
      visibility: hidden;
    }

    &::-webkit-scrollbar-thumb {
      visibility: hidden;
      border-radius: 10px;
      background-color: #fff;
      background-image: -webkit-gradient(
        linear,
        40% 0%,
        75% 84%,
        from(#676a6c),
        to(#676a6c),
        color-stop(0.6, #676a6c)
      );
    }
  }

  @media print {
    .hide-print {
      display: none !important;
    }

    .show-print {
      display: block !important;
    }
  }

  .show-print {
    display: none;
  }
}

// @media screen and (max-width: 576px) {
//   .personal-info-tabs {
//     flex-wrap: wrap;
//     overflow-x: scroll;
//   }
// }

.MuiIconButton-root {
  // color:rgba(0, 0, 0, 0.54)!important;
  &:hover {
    color: #1ab394 !important;
  }
}

.MuiDataGrid-root .MuiDataGrid-toolbar {
  flex-wrap: wrap;
  .MuiIconButton-root {
    height: 50px;
    width: 50px;
    .MuiIconButton-label {
      .MuiButton-root {
        background: none !important;
        .MuiButton-startIcon {
          margin: 0 !important;
        }
      }
    }
  }
}

.configuration-table {
  width: 75%;
}

.configuration-edit {
  width: 25%;
  background-color: #f3f3f4;
  z-index: 1;
  padding-left: 20px;
  margin-top: -2px;
}

.edit {
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 5;
}

.left-side {
  background-image: url("/src/assets/styles/patterns/login_2.png");
}

.background-form {
  background-color: #eaeff4;
}

.login-wrapper {
  font-family: "Poppins", Helvetica, Arial, sans-serif;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px #eaeff4 inset !important;
  }
}

.specs-table {
  div[class^="MuiToolbar-root"],
  [class^="MuiTableFooter-root"] {
    display: none;
  }
}

@media screen and (max-width: 567px) {
  .login-wrapper {
    padding: 100px 0;
    .row {
      text-align: center;
      .form-control {
        text-align: center;
      }
    }
  }
}

.facebook-button,
.linkedin-button {
  color: #444444;
  display: inline-block;
  transition: 0.3s ease-out;

  &:hover {
    transform: translateY(-3px);
  }
}

// .div{
//   position: relative;
//   margin-top: 30px;
// }
// .aaa::after{
//   content: "";
//   background: #f3f3f4;
//   top: -25px;
//   left: 0;
//   right: 0;
//   position: absolute;
//   z-index: 100;
//   width: 100%;
//   height: 15px;
// }
